<template>
  <div>
    <ul class="Users">
      <li
        v-for="user in users"
        :key="user.id">
        <router-link
          :to="{ name: 'AccountUser', params: {'userID': user.id} }">
          <div class="Top">

            <div class="LeftSide">
              <span
                v-if="!user.isActive"
                class="Inactive">{{ mixWB('USER_INACTIVE') }}</span>
              <span class="FullName">{{ user.fullName }}</span>
              <span
                class="LastSeen"
                v-if="user.lastSeen">{{ mixWB('LAST_SEEN') }} {{ mixGetDate(user.lastSeen) }}</span>
              <ul class="Roles">
                <li
                  v-for="role in user.roles"
                  :key="role">
                  {{ mixWB(`ROLE_${role.toUpperCase()}`) }}
                </li>
              </ul>
            </div>
            <span
              v-if="canEditUser(user.id)"
              class="EditIcon">
              <EditIcon />
            </span>
          </div>
          <Stat
            v-if="user.roles.some(x=> ['screener', 'admin'].includes(x))"
            :stats="user"
            :monthsToShow="3" />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import EditIcon from '@/assets/svg/edit.svg?inline'
import { mapGetters } from 'vuex'
import { orderBy } from 'lodash-es'
import Stat from '@/components/Stat.vue'

export default {
  name: 'Users',
  computed: {
    ...mapGetters([
      'currentUser',
      'allUsers',
    ]),
    users() {
      return orderBy(
        this.allUsers,
        [
          'isActive',
          // Somehow it would sort by ABCabc and not AaBbCc, so this hack solves it.
          (item) => item.fullName.toUpperCase(),
        ],
        ['desc', 'asc'],
      )
    },
  },
  methods: {
    canEditUser(userID) {
      return this.currentUser.isAdmin
        || this.currentUser.isSuperUser
        || this.currentUser.id === userID
    },
  },
  components: {
    EditIcon,
    Stat,
  },
}
</script>

<style lang="stylus" scoped>
  .Users
    > li
      margin 0
      margin-bottom 15px
      list-style-type none
      > a
        padding 10px
        border 1px solid $color_grey_lighter
        box-shadow $box_shadow_1
        background $color_grey_lightestest
        display block
        transition background 300ms ease
        &:hover, &:focus
          background $color_grey_lightest

  .LastSeen
    color $color_grey
    font-size 0.825rem
    display inline-block
    margin-left 10px
  .Inactive
    background $color_salmon_darkest
    color white
    display inline-block
    padding 1px 3px
    margin-right 5px
  .FullName
    font-weight bold
    font-size 1.125rem
    display inline
  .Top
    display flex
  .EditIcon
    margin-left auto
    width 20px
    svg
      transition fill 300ms ease
      fill $color_primary
  >>> .Stat
    border-top 1px solid $color_grey_lighter
    margin-top 10px
    padding-top 5px
  a:hover, a:focus
    .EditIcon svg
      fill $color_primary_darkest
  .Roles
    margin-top 5px
    > li
      display inline-block
      padding 2px 5px
      border 1px solid $color_grey_lighter
      border-radius 2px
      margin-right 4px
      font-size 0.875rem
      color $color_grey
      background-color $color_grey_lighter
  .CreateButton
    width auto
    margin-left auto
</style>
